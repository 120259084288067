import logo from './logo.svg';
import './App.css';
import Logo from './components/Logo'
import Main_Points from './components/main_points/Main_points'
import Consultation from './components/consultation/consultation'
import Trust_Process from './components/trust_process/Trust_process'
import Preview from './components/preview/Preview'
import Contact from './components/contact/Contact'
import TopMenu from './components/top_menu/TopMenu'
import Dash from './components/dashboard/Dash'
import React, {useState, useEffect} from "react";
import  { Redirect, BrowserRouter as Router, Route, Switch, useHistory, useCallback } from 'react-router-dom'

function App() {


  // Global Variables
  const history = useHistory()
  //const teamHandleClick = () => history.push(`/team`);//useCallback(() => history.push('/team'), [history]);


  // Event listener for parallax scrolling
  const [offsetY, setOffsetY] = useState();
  const handleScroll = () => setOffsetY(window.pageYOffset)

 // Globalize listener
  useEffect(()=> {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener('scroll',handleScroll);
      }, [])



    // Check for mobile device
    if (window.screen.width < 1000) {
        window.location.assign("https://mobile.axm.ai/")
     }



  return (
    <Router>
    <div className="App">

        <Switch>
            <Route exact path="/">
              <TopMenu />
                <Logo/>
                <Main_Points scroller={offsetY} />
                <Preview />
                <Consultation/>
                <Trust_Process/>
                <Contact />
            </Route>

            <Route exact path='/preview'>
              <Dash history={history} />
              <br></br>
              <br></br>
              <br></br>
              <div className='breaker'></div>
            </Route>


        </Switch>

    </div>
    </Router>
  );
}

export default App;
