import React from 'react'
import './Main_point_shapes.css'
import Nodes from './Main_point_shapes_node'

function Main_point_shapes() {
    return (
        <div className="holder">
                <Nodes/>
                <div>
                    <p className="supply_title">Analytics for data any shape, any size.</p>
                    <p className="supply_text">Custom built applications and reports for any type of company, no matter how big or small... Make it available externally to your customers/stakeholders or secure the data for your eyes only.</p>
                </div>
                

        </div>
    )
}

export default Main_point_shapes
