import React from 'react'
import './consultation.css'

function consultation() {
    return (
        <div className="consult_grand_holder">
            <h1 className="title">Consultations and implementation for anything and everything.</h1>

            <div className="consult_holder">

            <div className="consult_item">
                    <p className="consult_text">Predictive Modeling</p>
                </div>
                <div className="consult_item">
                    <p className="consult_text">Artificial Intelligence</p>
                </div>
                <div className="consult_item">
                    <p className="consult_text">Application Development</p>
                </div>
                <div className="consult_item">
                    <p className="consult_text">Online Marketing</p>
                </div>
                <div className="consult_item">
                    <p className="consult_text">Operational Efficiency</p>
                </div>
                <div className="consult_item">
                    <p className="consult_text">Dashboards/Reports</p>
                </div>
                <div className="consult_item">
                    <p className="consult_text">Tool Integrations</p>
                </div>
                <div className="consult_item">
                    <p className="consult_text">Cloud Migrations</p>
                </div>

            </div>

            <div className="breaker"></div>
            
        </div>
    )
}

export default consultation
