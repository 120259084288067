import React from 'react'
import Growth from './Growth.png'
import './Main_point_predict.css'
import Graph from './Main_point_predict_graph'

function Main_point_predict() {
    // style={{transform: `rotate(${props.scroller}deg)`}}

    return (
        <div className="holder">
            <div className="growth_holder" >
                <Graph />
                <img src={Growth} className="growth_class" ></img>
            </div>
            <div>
                    <p className="supply_title">Actionable predictive insights, now.</p>
                    <p className="supply_text">Make data driven decisions with realistic predictions generated with meaningful statistics and state of the art machine learning models... Take action for tomorrow, today.</p>
            </div>
        </div>
    )
}

export default Main_point_predict
