import React from 'react'
import './Main_point_predict_graph.css'

function Main_point_predict_graph() {
    return (
        <div className="graph_frame">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
        </div>
    )
}

export default Main_point_predict_graph
