import React from 'react'
import './Trust_Graphic.css'

function Trust_Graphic() {
    return (
        <div className="trust_graphic_holder">

            <div className="trust_graphic_frame">

                {/* Grey Boxes */}
                <div className="trust_graphic_obj1"> 
                    <p className="trust_graphic_obj_txt1">Define problems</p>
                </div>
                <div className="trust_graphic_obj2"> 
                    <p className="trust_graphic_obj_txt2">Collect data</p>
                </div>
                <div className="trust_graphic_obj3"> 
                    <p className="trust_graphic_obj_txt3">Generate analytics</p>
                </div>
                <div className="trust_graphic_obj4"> 
                    <p className="trust_graphic_obj_txt4">Take action</p>
                </div>

                {/* Purple Lines */}

                <div className="trust_graphic_line1"></div>
                <div className="trust_graphic_line2"></div>
                <div className="trust_graphic_line3"></div>


            </div>
            
        </div>
    )
}

export default Trust_Graphic
