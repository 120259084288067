import React from 'react'
import './Main_point_shapes_node.css'

function Main_point_shapes_node() {
    return (
        <div className="node_holder">

            <div className="main_node"></div>
            <div className="green1_node"></div>
            <div className="green2_node"></div>
            <div className="white1_node"></div>
            <div className="white2_node"></div>
            <div className="white3_node"></div>
            <div className="pink1_node"></div>
            <div className="pink2_node"></div>
            <div className="pink3_node"></div>
            <div className="pink4_node"></div>
            <div className="pink5_node"></div>
            
        </div>
    )
}

export default Main_point_shapes_node
