import React from 'react'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import './KPI_two.css'



function KPI_two() {
    const data = [
        {name: "March", Customers:121},
        {name: "April", Customers:136},
        {name: "May", Customers:92},
        {name: "June", Customers:147}
    ]
    return (
        <div className="kpi_holder">
            <h1 className="kpi_two_subtext">Customers trending</h1>
            <p className="cost_graph_desc">*Unique credit cards from 3/1-9/31... Hover to view more*</p>
            <LineChart width={400} height={150} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="Customers" stroke="#7F00FF" />
                <XAxis dataKey="name" stroke="#EEE0FF" />
                <YAxis stroke="#EEE0FF" />
                <Tooltip             
                        wrapperStyle={{ backgroundColor: "black" }}
                        labelStyle={{ color: "black" }}
                        itemStyle={{ color: "purple" }}
                        formatter={function(value, name) {
                        return `${value}`;
                        }}
                        labelFormatter={function(value) {
                        return `${value}`;
                        }}
             />
            </LineChart>
      </div>
    )
}

export default KPI_two
