import React from 'react'
import './Trust_process.css'
import Trust_Graphic from './Trust_Graphic'

function Trust_process() {
    return (
        <div className="trust_holder">

            <h1 className="title">Trust the process.</h1>
            <p className="subtext">By leveraging strategies used in fortune 500 companies, you can be sure that axm solutions create competitive advantages that are built to last.</p>
            <Trust_Graphic />
            <div className="breaker"></div>
        </div>
    )
}

export default Trust_process
