import React, { useState, useEffect } from 'react';
import './Dash.css'
import {useHistory} from 'react-router-dom'
import KPI_one from './kpis/KPI_one'
import KPI_two from './kpis/KPI_two'
import KPI_three from './kpis/KPI_three'
import Cost_breakdown from './cost_analysis/Cost_breakdown'
import Cost_analysis from './cost_analysis/Cost_analysis'
import Advanced from './advanced/Advanced_holder'





function Dash(props) {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    let history = useHistory();
    let back_home = () => {history.push('/')}

    return (
        <div>
            <div className="back_button" onClick={back_home}> 
                <p className="button_subtext">back home</p>
            </div>
            <h1 className="dash_title">Hop Harvest Brewery Report</h1>
            <br></br>
            <div className="kpi_shelf">
                <KPI_one/>
                <KPI_two/>
                <KPI_three/>
            </div>

            <div className="breaker"></div>

            <div className="cost_breakdown_holder">
                <Cost_breakdown/>
                <Cost_analysis/>
            </div>

            <div className="breaker"></div>

            <Advanced />

        
        </div>
    )
}

export default Dash
