import React from 'react'
import Main_Point_Shapes from './Main_point_shapes'
import Main_Point_Automation from './Main_point_automation'
import Main_Point_Predict from './Main_point_predict'

function Main_points(props) {
    return (
        <div>
            <Main_Point_Shapes/>
            <br></br>
            <br></br>
            <Main_Point_Automation scroller={props.scroller} />
            <br></br>
            <br></br>
            <Main_Point_Predict />
            <div className="breaker"></div>
        </div>
    )
}

export default Main_points
