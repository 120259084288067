import React from 'react'
import phone from './phone.png'
import email from './email.png'
import {useHistory} from 'react-router-dom'
import './TopMenu.css'

function TopMenu() {
    let history = useHistory();
    let view_preview = () => {history.push('/preview')}

  return (
    <div className='app_header'>
        <a href="tel:860-597-7110">
            <img className='top_img_ph' src={phone}></img>
        </a>
        <a href='mailto:axm.analytics@gmail.com'>
            <img className='top_img_em' src={email}></img>
        </a>

        <div className='top_preview_button' onClick={view_preview}>
            <p className='top_preview_text'>Preview</p>
        </div>
    </div>
  )
}

export default TopMenu