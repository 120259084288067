import React from 'react'
import logo from './Logo.png'
import './Logo.css'

function Logo() {
    return (
        <div className="logo_center">
            <img className='logo_img' src={logo}></img>
            <p className="slogan">Predictive and informative analytical data solutions</p>
            <div className="breaker"></div>
            <p className="slogan_sub">AXM is a data-driven consulting firm that helps organizations achieve
            their goals by strengthening their strategies and delivering scalable, customized solutions with a 
            first in class approach.
            </p>
            <br></br>
            <p className="slogan_sub">
            By leveraging cutting edge technologies, future-proof frameworks and disruptively predictive algorithms, your organization can rapidly advance 
            beyond your competition and proactively take control of your market and operations.
            </p>
            <div className="breaker"></div>

        </div>
    )
}

export default Logo
