import React from 'react'
import './Cost_breakdown.css'

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;
const toPercentBar = (decimal) => `${(decimal * 100)}%`;

const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;

  return toPercent(ratio, 2);
};

const renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => result + entry.value, 0);
  
    return (
      <div className="customized-tooltip-content">
        <p className="total">{`${label} (Total: $${total})`}</p>
        <ul className="list">
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: $${entry.value} (${getPercent(entry.value, total)})`}
            </p>
          ))}
        </ul>
      </div>
    );
  };

const data = [
    {
      month: 'January',
      "Employees": 4000,
      "Brewery Supplies": 2400,
      "Operationial": 2400,
    },
    {
      month: 'February',
      "Employees": 3000,
      "Brewery Supplies": 1398,
      "Operationial": 2210,
    },
    {
      month: 'March',
      "Employees": 2000,
      "Brewery Supplies": 9800,
      "Operationial": 2290,
    },
    {
      month: 'April',
      "Employees": 2780,
      "Brewery Supplies": 3908,
      "Operationial": 2000,
    },
    {
      month: 'May',
      "Employees": 1890,
      "Brewery Supplies": 4800,
      "Operationial": 2181,
    },
    {
      month: 'June',
      "Employees": 2390,
      "Brewery Supplies": 3800,
      "Operationial": 2500,
    },
    {
      month: 'July',
      "Employees": 3490,
      "Brewery Supplies": 4300,
      "Operationial": 2100,
    },
  ];

function Cost_breakdown() {
    return (
        <div className="cost_graph_holder">
            <h1 className="cost_graph_title">Cost breakdown chart</h1>
            <p className="cost_graph_desc_sub">*Custom category breakout... Hover to view details*</p>
        <AreaChart
                width={800}
                height={300}
                data={data}
                stackOffset="expand"
                margin={{
                    top: 10,
                    right: 10,
                    left: 0,
                    bottom: 0,
                }}
                >
          <XAxis dataKey="month" stroke="#EEE0FF" />
          <YAxis tickFormatter={toPercentBar} stroke="#EEE0FF" />
          <Tooltip content={renderTooltipContent} />
          <Area type="monotone" dataKey="Brewery Supplies" stackId="1" stroke="#00EEBB" fill="#00EEBB" />
          <Area type="monotone" dataKey="Employees" stackId="1" stroke="#FF00C7" fill="#FF00C7" />
          <Area type="monotone" dataKey="Operationial" stackId="1" stroke="#7F00FF" fill="#7F00FF" />
        </AreaChart>

            
        </div>
    )
}

export default Cost_breakdown
