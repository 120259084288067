import React from 'react'
import './Main_point_automation.css'
import Gear from './Gear.png'

function Main_point_automation(props) {

    return (
        <div className="holder">
            <div className="gear_holder" style={{transform: `rotate(${props.scroller}deg)`}}>
                <img src={Gear} className="gear_class" ></img>
            </div>
            <div>
                    <p className="supply_title">Automation of business, made easy.</p>
                    <p className="supply_text">Identify opportunities for automation of your business and spend your time where it really counts... Find what makes your business tick and capitalize on mechanical delegation.</p>
            </div>
        </div>
    )
}

export default Main_point_automation
