import React from 'react'
import './Cost_analysis.css'
function Cost_analysis() {
    return (
        <div className="cost_analy_holder">
            <h1 className="cost_analy_title">Cost analyis breadown</h1>
            <p className="cost_graph_desc_sub">*Machine learning models refreshed as of 12/1*</p>
            <div>
                <p className="cost_analy_text">Largest running cost: <span className="cost_analy_high1">Brew Supplies</span></p>
                <p className="cost_analy_text">Most volatile cost: <span className="cost_analy_high1">Employees</span></p>
                <p className="cost_analy_text">Seasonal trend predicted: <span className="cost_analy_high1">True</span></p>
                <p className="cost_analy_text">Estimated next cost: <span className="cost_analy_high1">$10,980.81</span></p>
                <p className="cost_analy_text">Seasonal impact: <span className="cost_analy_high1">+$2,014.81</span></p>
            </div>


            
        </div>
    )
}

export default Cost_analysis
