import React from 'react'
import './Preview.css'
import {useHistory} from 'react-router-dom'

function Preview() {
    let history = useHistory();
    let view_preview = () => {history.push('/preview')}

    return (
        <div>
            <br></br>
            <br></br>
            <br></br>
            <h1 className="title">Preview of the action</h1>
            <p className="subtext">Take a look at this sample dashboard created to enable quick and easy insights.</p>

            <div className="button_holder">
                        <div className="button" onClick={view_preview}>
                            <p className="button_subtext">View the dashboard</p>
                        </div>
            </div>
            <br></br>
            <br></br>
            <br></br>

            <div className="breaker"></div>

            
        </div>
    )
}

export default Preview
